<template>
    <section>
        <!--工具条-->
        <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
          <!--列表-->
            <el-table
                :data="users" 
                highlight-current-row
                row-key = "Id"
                lazy
                :load = "load"
                :tree-props = "{children: 'children',hasChildren: 'IsHasChildren'}"
                :fit="true"
                @current-change="selectCurrentRow"
                @row-dblclick="checkInfo"
                :row-class-name="tableRowClassName"
                style="width: 100%">
                    <el-table-column type = "index"></el-table-column>
                    <el-table-column label="工作项目" prop="WorkItem" min-width="260px" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <i class="el-icon-timer" v-if = "scope.row.IsHasChildren == true"></i>
                            <i class="el-icon-timer" v-else></i>
                            {{scope.row.WorkItem}}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="具体描述及要求" width="200" prop="WorkDescription" ></el-table-column> -->
                    <el-table-column label="计划完成时间" align="center" prop="PlanComplateTime" :formatter="formatPlanComplateTime" min-width="150px"></el-table-column>
                    <el-table-column label="责任人" min-width="100px" prop="PersonOfDuty"></el-table-column>
                    <!-- <el-table-column label="跨出公司" prop="OutCompany">
                        <template slot-scope = "scope">
                            <el-tag v-if="scope.row.OutCompany == null">
                                无
                            </el-tag>
                            <el-tag v-else>{{scope.row.OutCompany}}</el-tag>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column label="跨出部门" align="center" sortable prop="OutDepartment">
                        <template slot-scope = "scope">
                            <el-tag v-if="scope.row.OutDepartment == null">
                                无
                            </el-tag>
                            <el-tag v-else>{{scope.row.OutDepartment}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="跨出人员" align="center" sortable prop="OutPeople">
                        <template slot-scope = "scope">
                            <el-tag v-if="scope.row.OutPeople == null">
                                无
                            </el-tag>
                            <el-tag v-else>{{scope.row.OutPeople}}</el-tag>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column label="跨入公司" prop="AcceptCompany">
                        <template slot-scope = "scope">
                            <el-tag v-if="scope.row.AcceptCompany == null">
                                无
                            </el-tag>
                            <el-tag v-else>{{scope.row.AcceptCompany}}</el-tag>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="协同部门" prop="AcceptDepartment" sortable min-width="100px">
                        <template slot-scope = "scope">
                            <el-tag v-if="scope.row.AcceptDepartment == null">
                                
                            </el-tag>
                            <el-tag v-else>{{scope.row.AcceptDepartment}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="协同人员" prop="AcceptPeople" sortable min-width="100px">
                         <template slot-scope = "scope">
                            <el-tag v-if="scope.row.AcceptPeople == null">
                                
                            </el-tag>
                            <el-tag v-else>{{scope.row.AcceptPeople}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="任务类型" prop="CommonTaskClassifyText" :formatter="formatCommonTaskClassifyText" min-width="100px"></el-table-column>
                     <el-table-column label="任务状态" prop="Status" sortable min-width="100px">
                            <template slot-scope = "scope">
                                <el-tooltip placement="top">
                                   <!-- <div slot="content" v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5 || scope.row.Status == 8">
                                       任务待审核
                                   </div>
                                    <div slot="content" v-if="scope.row.Status == 6">
                                        已退回
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == 0">
                                        已完成{{  scope.row.TaskProgressValue }}%
                                    </div> -->
                                     <div slot="content" v-if="scope.row.Status == 0 || scope.row.Status == 2">
                                        已完成{{  scope.row.TaskProgressValue }}%
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5 || scope.row.Status == 8">
                                        审核中
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == 6 || scope.row.Status == 3">
                                        已退回
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == -1">
                                        已取消
                                    </div>
                                     <div slot="content" v-if="scope.row.Status == 9">
                                        审核完成
                                    </div>
                                    <div>
                                        <el-tag v-if="scope.row.Status == 0" effect="dark"> 进行中</el-tag>
                                        <el-tag v-if="scope.row.Status == 1" effect="dark" type="warning">已完成审核中</el-tag>
                                        <el-tag v-if="scope.row.Status == 2" effect="dark" type="success">已完成</el-tag>
                                        <el-tag v-if="scope.row.Status == -1" effect="dark" type="info">已取消</el-tag>
                                        <el-tag v-if="scope.row.Status == 3" effect="dark" type="danger">退回</el-tag>
                                        <el-tag v-if="scope.row.Status == 4" effect="dark" type="warning">新建任务待审核</el-tag>
                                        <el-tag v-if="scope.row.Status == 5" effect="dark" type="danger">取消任务待审核</el-tag>
                                        <el-tag v-if="scope.row.Status == 6" effect="dark" type="danger">新建任务退回</el-tag>
                                        <el-tag v-if="scope.row.Status == 8" effect="dark" type="warning">阅知审核中</el-tag>
                                        <el-tag v-if="scope.row.Status == 9" effect="dark" type="success">审核完成</el-tag>
                                    </div>
                                </el-tooltip>
                            </template>
                    </el-table-column>
                    <el-table-column label="任务来源" prop="Source" :formatter="formatSource" min-width="100px"></el-table-column>
                    <el-table-column label="创建时间" prop="CreateTime" :formatter = "formatCreateTime" sortable min-width="100px">
                        <template slot-scope="scope">
                            <el-tooltip placement="top">
                                <div slot="content">
                                    {{ formatTipCreateTime( scope.row) }}
                                </div>
                                <div>
                                    {{ formatCreateTime( scope.row) }}
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="备注"  prop="Remark"></el-table-column> -->
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-tooltip v-if="localuser == scope.row.PersonOfDuty" placement="top" content="双击可以维护任务进度喔~" disabled="false">
                                <el-row>
                                     <el-button type="text" size="medium"  @click="checkInfo(scope.row)">维护</el-button>
                                     <el-button type="text" v-if="scope.row.Status == 3 || scope.row.Status == 6" style="color: red;" @click="readdSubmit(scope.row)">重新提交</el-button>
                                </el-row>
                            </el-tooltip>
                            <el-tooltip v-else placement="left" content="双击也可以查看任务进度哦~" disabled="false">
                                <el-row>
                                     <el-button type="text" size="medium"  @click="checkInfo(scope.row)">查看</el-button>
                                     <!-- <el-button type="text" v-if="scope.row.Status == 3 || scope.row.Status == 6" style="color: red;" @click="readdSubmit(scope.row)">重新提交</el-button> -->
                                </el-row>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
              <!-- 分页器 -->
                <el-pagination align='center' @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :page-count="total" :total="totaldata">
                </el-pagination>
        <!--工作任务添加-->
            <el-dialog 
                title="新增跨部门协同任务" 
                :visible.sync="addFormVisible" 
                v-model="addFormVisible" 
                @close = "clickclose"  
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                center>
                <el-form :model="addForm" label-width="85px" :rules="addFormRules" ref="addForm" style="white-space:nowrap;">
                    <el-form-item label="工作项目:" prop="WorkItem">
                        <el-input maxlength="30" show-word-limit  v-model="addForm.WorkItem"></el-input>
                    </el-form-item>
                    <el-form-item label="工作描述:" prop="WorkDescription">
                        <el-input maxlength="500" show-word-limit  v-model="addForm.WorkDescription" type="textarea"></el-input>
                    </el-form-item>
                    <el-row>
                        <el-col :span="12">
                             <el-form-item label="计划完成时间:" prop="PlanComplateTime">
                                <el-date-picker  :picker-options="pickerOptions0"  v-model ="addForm.PlanComplateTime" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期" style="left: 30px;"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                                    <el-row>
                                    <el-col :span="20">
                                        <el-form-item label="责任人:" prop="PersonOfDuty">
                                            <el-input v-model="addForm.PersonOfDuty" readonly>{{dutyneedname}}</el-input>
                                        </el-form-item>
                                    </el-col>
                                    <!-- <el-col :span="2">
                                    <el-button type="text">
                                        <userchoosetool @dutycallFunction="dutycallFunction" :callbackmsg = "dutypeo"></userchoosetool>
                                    </el-button>
                                    </el-col> -->
                                </el-row>
                            </el-col>
                    </el-row>
                        <el-row>
                            <el-col :span="0.1" style="margin-top:10px">
                            <span style="color: red;">*</span>
                        </el-col>
                        <el-col :span="9">
                            <el-form-item label="协同人员:" prop="AcceptPeople">
                                <el-input v-model="addForm.AcceptPeople" readonly placeholder="请选择协同人员">{{acceptpeoplename}}</el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                        <el-button type="text">
                            <userchoosetool @acceptcallFunction="acceptcallFunction" :callbackmsg = "acceptpeoplecall"></userchoosetool>
                        </el-button>
                        </el-col>
                    </el-row>
                        <!-- <el-row>
                        <el-col :span="12"> -->
                            <!-- <el-row>
                                <el-col :span="0.1" style="margin-top:10px">
                                    <span style="color: red;">*</span>
                                </el-col>
                                <el-col :span="20">
                                <el-form-item label="跨出人员:" prop="OutPeople">
                                    <el-input v-model="addForm.OutPeople" readonly>{{outpeoplename}}</el-input>
                                </el-form-item>
                                    </el-col>
                                    <el-col :span="2">
                                    <el-button type="text">
                                        <userchoosetool @outcallFunction="outcallFunction" :callbackmsg = "outpeoplecall"></userchoosetool>
                                    </el-button>
                                </el-col>
                            </el-row>
                            </el-col> -->
                            <!-- <el-col :span="12">
                                <el-row>
                                    <el-col :span="0.1" style="margin-top:10px">
                                    <span style="color: red;">*</span>
                                </el-col>
                                <el-col :span="20">
                                    <el-form-item label="跨入人员:" prop="AcceptPeople">
                                        <el-input v-model="addForm.AcceptPeople" readonly>{{acceptpeoplename}}</el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="2">
                                <el-button type="text">
                                    <userchoosetool @acceptcallFunction="acceptcallFunction" :callbackmsg = "acceptpeoplecall"></userchoosetool>
                                </el-button>
                                </el-col>
                            </el-row>
                            </el-col> -->
                    <!-- </el-row> -->
                    <el-form-item label="备注:" prop="Remark">
                        <el-input maxlength="50" show-word-limit  v-model="addForm.Remark" type="textarea"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="是否指派:" prop="IsAssign" v-show="assigncontrol">
                        <el-switch v-model="addForm.IsAssign" @change="clickAssign"></el-switch>
                    </el-form-item> -->
                </el-form>
                    <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click.native="addSubmit" v-show="showSubmit">提交</el-button>
                    <el-button type="primary" @click="nextStep" v-show="showNext">下一步</el-button>
                    </div>
                </el-dialog>
        <!--工作任务添加并指派-->
                <el-dialog 
                    title="指派任务" 
                    :visible.sync="addAssignFormVisible" 
                    v-model="addAssignFormVisible" 
                    :close-on-press-escape="false"
                    :close-on-click-modal="false"
                    center>
                    <el-form label-width="85px" style="white-space:nowrap;">
                            <el-row>
                                <el-col :span="0.1" style="margin-top:10px">
                                        <span style="color: red;">*</span>
                                </el-col>
                            <el-col :span="8">
                                <el-form-item label="被指派人:" prop="AssignName">
                                    <el-input v-model="addForm.AssignName" placeholder="请选择被指派人" readonly>{{needName}}</el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2">
                            <el-button type="text">
                                <userchoosetool @assigncallFunction="assigncallFunction" :callbackmsg = "assignpeo"></userchoosetool>
                            </el-button>
                            </el-col>
                        </el-row>
                            <el-row>
                                <el-col :span="0.1" style="margin-top:10px">
                                    <span style="color: red;">*</span>
                                </el-col>
                                <el-col :span="20">
                                        <el-form-item label="完成后需我审核:" prop="IsNeedApproval">
                                        <el-switch v-model="addForm.IsNeedApproval" style="left: 5%;"></el-switch>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        <el-row>
                            <el-col :span="0.1" style="margin-top:10px">
                                <span style="color: red;">*</span>
                            </el-col>
                            <el-col :span="23">
                                <el-form-item label="工作项目:" prop="WorkItem2">
                                    <el-input v-model="addForm.WorkItem2" maxlength="30" show-word-limit></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="0.1" style="margin-top:10px">
                                <span style="color: red;">*</span>
                            </el-col>
                            <el-col :span="23">
                                <el-form-item label="工作描述:" prop="WorkDescription2">
                                    <el-input v-model="addForm.WorkDescription2" type="textarea" maxlength="500" show-word-limit></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="0.1" style="margin-top:10px">
                                <span style="color: red;">*</span>
                            </el-col>
                            <el-col :span="20">
                                <el-form-item label="计划完成时间:" prop="PlanComplateTime2">
                                    <el-date-picker v-model ="addForm.PlanComplateTime2" :picker-options="pickerOptions0" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期" style="left: 2%;"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                        <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click.native="addSubmit">提交</el-button>
                        </div>
                    </el-dialog>
        <!--编辑任务界面-->
            <el-dialog 
                title="编辑任务" 
                :visible.sync="editFormVisible" 
                v-model="editFormVisible" 
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                center>
                <el-form :model="editForm" label-width="85px" :rules="editFormRules" ref="editForm">
                    <el-form-item label="任务类型:" prop="CommonTaskClassifyText">
                        <el-input v-model="CommonTaskClassifyText" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="工作项目:" prop="WorkItem">
                        <el-input v-model="editForm.WorkItem" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="工作描述:" prop="WorkDescription">
                        <el-input v-model="editForm.WorkDescription" type="textarea" maxlength="500" show-word-limit></el-input>
                    </el-form-item>
                    <el-row style="height: 40px; margin-bottom: 20px;">
                        <el-col :span="0.1">
                            <span style="color: red;">*</span>
                        </el-col>
                        <el-col :span="2.5">
                            <span>计划完成时间:</span>
                        </el-col>
                        <el-col :span="7">
                            <el-date-picker :picker-options="pickerOptions0" v-model ="editForm.PlanComplateTime" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期"></el-date-picker>
                    </el-col>
                    </el-row>
                        <el-form-item label="备注:" prop="Remark">
                        <el-input v-model="editForm.Remark" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                </el-form>
                    <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click.native="editSubmit">提交</el-button>
                    </div>
            </el-dialog>
        <!--查看界面-->
            <el-dialog 
                title="查看跨部门任务" 
                :visible.sync="checkVisible" 
                v-model="checkVisible" 
                @close="checkclose"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                style="width: 140%; left: -300px; top:-40px"
                center
                >
                <div class="ocdiv">
                    <el-col :span="16">
                    <el-form :model="checkForm" label-width="85px" ref="checkForm" style="white-space:nowrap;">
                        <el-form-item label="任务类型:" prop="CommonTaskClassifyText">
                           <el-input v-model="CommonTaskClassifyText" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="工作项目:" prop="WorkItem">
                            <el-input v-model="checkForm.WorkItem" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="工作描述:" prop="WorkDescription">
                            <el-input v-model="checkForm.WorkDescription" type="textarea" disabled :autosize="{minRows:1,maxRows:4}"></el-input>
                        </el-form-item>
                         <el-row>
                             <el-col :span="12" v-show="showAssign">
                                <el-form-item label="指派人:" prop="AssignName">
                                    <el-input v-model="checkForm.AssignName" disabled></el-input>
                                </el-form-item>
                            </el-col>
                             <!-- <el-col :span="12">
                                <el-form-item label="创建人:" prop="CreatedBy">
                                    <el-input v-model="checkForm.CreatedBy" disabled></el-input>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="12">
                                <el-form-item label="责任人:" prop="PersonOfDuty">
                                    <el-input v-model="checkForm.PersonOfDuty" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row style="height: 40px; margin-bottom: 20px;">
                            <el-col :span="12">
                                <el-form-item label="创建时间:" prop="CreateTime">
                                    <el-date-picker v-model ="checkForm.CreateTime" type = "date" value-format="yyyy-MM-dd" disabled></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="计划完成时间:" prop="PlanComplateTime">
                                    <el-date-picker v-model ="checkForm.PlanComplateTime" type = "date" value-format="yyyy-MM-dd" disabled></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!-- <el-form-item label="完成时间:" prop="PlanComplateTime">
                        <el-date-picker v-model ="checkForm.PlanComplateTime" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期" disabled></el-date-picker>
                        </el-form-item> -->
                            <!-- <el-row>
                                <el-col :span="12">
                                    <el-form-item label="跨出部门:" prop="OutDepartment">
                                        <el-input v-model="checkForm.OutDepartment" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="跨出人员:" prop="OutPeople">
                                        <el-input v-model="checkForm.OutPeople" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row> -->
                            <el-row>
                                <el-col :span="12">
                                    <el-form-item label="协同部门:" prop="AcceptDepartment">
                                        <el-input v-model="checkForm.AcceptDepartment" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="协同人员:" prop="AcceptPeople">
                                        <el-input v-model="checkForm.AcceptPeople" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        <el-form-item label="备注:" prop="Remark">
                            <el-input v-model="checkForm.Remark" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="是否审核:" prop="IsNeedApproval">
                            <el-switch v-model="checkForm.IsNeedApproval" disabled></el-switch>
                        </el-form-item>
                        <el-form-item label="任务进度:">
                            <el-progress :percentage="pValue1" :stroke-width="12" style="margin-top: 10px;" ></el-progress>
                        </el-form-item>
                    </el-form>
                </el-col>
                    <el-col :span="8">
                        <el-card class="occard">
                            <div slot="header">
                                <span style="font-size:20px">工作进度维护</span>
                                <el-row v-show="isocpro">
                                <el-button  type="text" @click="addpro">新增</el-button>
                                <!-- <el-button  type="text" @click="editpro">编辑</el-button> -->
                                <el-button  type="text" @click="deletepro">删除</el-button>
                                </el-row>            
                            </div> 
                            <div class="organizediv">
                                <!--列表-->
                                <el-table
                                    :data="ocs" 
                                    highlight-current-row
                                    :row-class-name ="taskProgressRowClassName"
                                    @row-click = "onRowClick"
                                    @current-change="selectProRow"
                                    >
                                    <el-table-column type = "index"></el-table-column>
                                    <el-table-column label="进度内容" prop="ProgressDescription"></el-table-column>
                                    <el-table-column label="进度" prop="ProgressValue" min-width="50%"></el-table-column>
                                    <el-table-column label="添加时间" min-width="85%" prop="CreatedOn" :formatter="formatCreatedOn"></el-table-column>
                                </el-table>
                            </div>
                        </el-card>
                    </el-col>
                    </div>
            </el-dialog>
        <!--新增工作进度界面-->
        <el-dialog 
            title="新增工作进度" 
            :visible.sync="addProgressVisible" 
            v-model="addProgressVisible"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            @opened="addopen"
            style="width: 80%; left: 15%;"
            center
            >
            <el-form :model="addProgressForm" :rules="addProgressFormRules">
                <el-form-item label="进度描述:" prop="ProgressDescription">
                    <el-input v-model="addProgressForm.ProgressDescription" 
                    placeholder="请尽可能的描述任务推进过程、量化数据和成果" maxlength="700"  show-word-limit type="textarea"></el-input>
                </el-form-item>
                    <el-form-item label="具体进度:">
                        <el-row>
                            <el-col :span="15">
                                <el-progress style="margin-top:10px" :percentage="pValue2" :color="customColor"></el-progress>
                            </el-col>
                            <el-col :span="1.5">
                                <div>
                                    <el-button-group>
                                        <el-button icon="el-icon-plus" @click="increase" v-show="showplus"></el-button>
                                        <el-button icon="el-icon-minus" style="float:right;" v-show="showminus"  @click="decrease"></el-button>
                                    </el-button-group>
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click.native="checkSubmit">完成</el-button>
            </div>
            </el-dialog>
    </section>
</template>
<script>
import util from '../../../util/date';
import {QueryPageOrganizeCoByUserCode,GetMyUpLeader,GetClassifyList,GetUserPostList,AddOrganizeCo,QueryTaskProgressByTaskId,AddTaskProgress,DeleteTaskProgress,QueryOrganizeChildTasksByParentId,CancelTask,DeleteTaskById,AssignTask,ReSubmitApproval,UpdateTaskContent,QueryTasksById} from '../../api/oa';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Userchoosetool from "../../components/Userchoosetool";
export default {
    components: { Toolbar,Userchoosetool },
    data(){
        return{
            currentPage: 1, // 当前页码
            total: null, // 总条数
            totaldata:null,
            pageSize:  10, // 每页的数据条数
            page: 1,
            localuser: null,
            pValue1: null,   //进度条数值
            pValue2: null,   //进度条数值   //进度条数值
            customColor: '#6f7ad3',   //进度条颜色
            ocs: [],
            buttonList: [],
            users: [],
            progresscheck: true,      //进行完成进度验证
            filters: {
               name: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            }, 
            currentRow: null,
            currentProgressRowIndex:null,
            CommonTaskClassifyText: null,
            singleAss: false,     //是否单独指派
            needName: null,
            dutyneedname: '',
            itemcontent: null,
            description: null,
            dutyneedname: '',
            outpeoplename: null,
            acceptpeoplename: null,
            upleader: null,
            dutypeo: 'dutypeo',
            assignpeo: 'assignpeo',
            outpeoplecall: 'outpeople',
            acceptpeoplecall: 'acceptpeople',
            buttonListmsg: 'taskmanage',
            showminus: false,
            showplus: true,
            isocpro: true,
            assigncontrol: true,
            showAssign: false,
            showSubmit: true,    //提交按钮是否显示
            showNext: false,
            editFormVisible: false,
            addFormVisible: false,
            checkVisible: false,
            addProgressVisible: false,
            CommonTaskClassifyList: [],    //任务类型
            addAssignFormVisible: false,  //新增并指派界面初始化
            addFormRules: {
                CommonTaskClassify: [
                    {required: true, message: '请选择任务类型', trigger: 'blur'}
                ],
                WorkItem: [
                    {required: true, message: '请填写项目名称', trigger: 'blur'}
                ],
                PlanComplateTime: [
                    {required: true, message: '请选择计划完成时间', trigger: 'blur'}
                ],
                WorkDescription: [
                    {required: true, message: '请输入工作内容', trigger: 'blur'}
                ]
            },
            addProgressFormRules: {
                ProgressDescription: [
                    {required: true, message: '请输入进度描述', trigger: 'blur'}
                ]
            },
            editFormRules: {
                  CommonTaskClassify: [
                    {required: true, message: '请选择任务类型', trigger: 'blur'}
                ],
                WorkItem: [
                    {required: true, message: '请填写工作项目', trigger: 'blur'}
                ],
                WorkDescription: [
                    {required: true, message: '请输入工作描述', trigger: 'blur'}
                ]
            },
            addForm: {                   //新增表单初始化
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsAssign: false,
                IsNeedApproval: false,
                CreatedBy: null,
                CreatedId: null,
                AssignName: null,
                AssignCode: null,
                PersonOfDuty: null,
                PersonOfDutyCode: null
            },
            editForm: {              //编辑表单初始化
                Id: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
            },
            checkForm: {            //查看表单初始化
                Id: null,
                CommonTaskClassify: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsNeedApproval: null
            },
            addProgressForm: {            //进度新增表单初始化
                ProgressDescription: null,
                CreatedBy: null,
                CreatedId: null
            }
        }
    },
    methods: {
        formatStatus: function (row, column) {
           return row.Status == 0 ? '进行中' : row.Status == 1 ? '审批中' : row.Status == 2 ? '已完成' : row.Status == -1 ? '已取消' : row.Status == 3 ? '退回' : '未知';
        },
        formatPlanComplateTime: function (row,colume) {
            return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime),'yyyy-MM-dd');
        },
        
        formatSource: function (row,column) {
            return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
        },
        formatCreateTime: function(row,column) {
            return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
        },
        formatTipCreateTime: function (row, column) {
            return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime),'yyyy-MM-dd hh:mm');
        },
        formatCreatedOn: function (row,colume) {
            return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn),'yyyy-MM-dd hh:mm');
        },
         formatCommonTaskClassifyText: function(row,colume){
            if(row.TaskClassify ==1){  
                return row.CommonTaskClassifyText;
            }else{
                if(row.TaskClassify ==2)
                {
                    return "跨部门协调";
                }
                else if(row.TaskClassify ==3)
                {
                    return "例会项目";
                }else if(row.TaskClassify ==4)
                {
                    return "年度计划";
                }else if(row.TaskClassify ==5){
                    return "专项会议";
                }
                else {
                    return "其他";
                }
            }
        },
         tableRowClassName({row, rowIndex}){
            var user = JSON.parse(window.localStorage.user);
            if (row.PersonOfDuty != user.name) {
                return 'success-row';
             }else{
                  return '';
             }      
        },
        formatData: function(row,colume){
            // if(row.WorkItem.length < 5){
            //     row.WorkItem+="         ";
            //     return row.WorkItem;
            // }else{
            //     return row.WorkItem;
            // }
        },
        callFunction(item) {
                this.filters = {
                name: item.search
            };
            this[item.Func].apply(this, item);
        },
        taskProgressRowClassName({row, rowIndex})
        {
            row.index = rowIndex;
        },
        onRowClick (row, event, column) {
            //行点击消除new标记
            this.currentProgressRowIndex = row.index;
        },
        selectchange(val){
            var ls=this.CommonTaskClassifyList;
            for(var a=0;a<ls.length;a++)
            {
                if(ls[a].Id==val)
                {
                    if(ls[a].IsNeedUpLeaderApproval==1)
                    {
                        this.$message({
                            message: '该任务类型下的任务需上级领导'+this.upleader+'审核成功后方可执行！',
                            type: 'warning',
                            duration: 15000
                        });
                        this.assigncontrol = false;
                    }else{
                       this.assigncontrol = true;
                  }
                }
            }
        },
        handleCurrentChange(val) {
                this.page = val;
                this.getOrganizeCos();
                this.page = 1;
        },
        selectCurrentRow(val) {
            this.currentRow = val;
        },
        selectProRow(val){
            this.proRow = val;
        },
        //获取上级级领导
        getLeader(code){
            // var user = JSON.parse(window.localStorage.user);
            // let para={userCode: code};
            // GetMyUpLeader(para).then((res) => {
            //     this.upleader = res.data.response;
            //     this.$message({
            //         message: '该跨部门协同任务需上级领导'+this.upleader+'审核成功后方可执行！',
            //         type: 'warning',
            //         duration: 15000
            //     });
            // });
        },
        getOrganizeCos(){
            var user = JSON.parse(window.localStorage.user);
            let para = {
                    userCode: user.sub,
                    pageIndex: this.page,
                    pageSize: this.pageSize,
                    queryKey: this.filters.name
            };
            QueryPageOrganizeCoByUserCode(para).then((res) => {
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
        },
        load(tree,treeNode,resolve){
            let para = {
                taskId: tree.Id,
            };
            QueryOrganizeChildTasksByParentId(para).then((res) => {
                resolve(res.data.response)
            });
        },
        //指派
            handleAssign(){
                 let row = this.currentRow;
                    if (!row) {
                    this.$message({
                        message: "请选择要指派的一行数据！",
                        type: "error",
                        duration: 10000
                    });
                        return;
                }else if(row.Status != 0){
                    this.$message({
                            message: '当前任务未审核通过，不能指派',
                            type: 'error',
                            duration: 10000
                        });
                }else if (row.ParentId != null){
                    this.$message({
                            message: '当前任务为子任务，不能指派',
                            type: 'error',
                            duration: 10000
                        });
                }else{
                        this.addAssignFormVisible = true;
                        this.singleAss = true;
                        this.addForm.WorkItem2 = row.WorkItem;
                        this.addForm.WorkDescription2 = row.WorkDescription;
                }
         },
         //新增
        handleAdd(){
            this.showNext = false;
            this.showSubmit = true;
            this.needName = null;
            this.addFormVisible = true;
            var user = JSON.parse(window.localStorage.user);
            this.dutyneedname = user.name;
            this.addForm.PersonOfDuty = user.name;
            this.addForm.PersonOfDutyCode = user.sub;
            let para = {userId: user.sub};
            GetUserPostList(para).then((res) => {
                this.addForm.OutDepartment = res.data.data[0].deptName;
                this.addForm.OutPeople = user.name;
                this.addForm.OutPeopleCode = user.sub;
            });
        },
        //新增提交
        addSubmit(){
             if(this.singleAss == false){
                    this.$refs.addForm.validate((valid) => {
                        if(valid){
                            let para = Object.assign({},this.addForm);
                            var user = JSON.parse(window.localStorage.user);
                            if(user && user.sub != null){
                                para.CreatedId = user.sub;
                                para.CreatedBy = user.name;
                            }else {
                                    this.$message({
                                        message: '用户信息为空，请先登录',
                                        type: 'error'
                                    });
                                    _this.$router.replace(_this.$route.query.redirect ?  _this.$route.query.redirect : "/");
                                }
                            if(this.addForm.WorkItem != null && this.addForm.WorkDescription != null && this.addForm.PlanComplateTime != null && this.addForm.AcceptPeople != null) {
                                            AddOrganizeCo(para).then((res) => {
                                                if(res.data.success) {
                                                    this.$message({
                                                        message: res.data.msg,
                                                        type: 'success'
                                                    });
                                                    this.$refs['addForm'].resetFields();
                                                    this.addFormVisible = false;
                                                    this.addAssignFormVisible = false;
                                                    this.needName = null;
                                                    this.dutyneedname = null;
                                                    this.outpeoplename = null;
                                                    this.acceptpeoplename = null;
                                                    this.getOrganizeCos();
                                                }else{
                                                    this.$message({
                                                        message: res.data.msg,
                                                        type: 'error'
                                                    });
                                                }  
                                            });
                                 }else{
                                     this.tipMessage();
                                 }
                                
                            // }
                        }
                    });
                }else{
                     let para = Object.assign({},this.addForm);
                     AssignTask(para).then((res) => {
                            if(res.data.success) {
                                this.$message({
                                    message: res.data.msg,
                                    type: 'success'
                                });
                                this.addAssignFormVisible = false;
                                this.needName = null;
                                this.singleAss = false;
                                this.getOrganizeCos();
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'error'
                                });
                            }
                        });
                    }
        },
         //退回后再次提交
        readdSubmit(row){
            this.$confirm('请尽量完善任务描述后再次提交,确定要提交吗？', '提示', {}).then(() => {
                let para = {taskId: row.Id,taskClassify: row.TaskClassify,taskParentId: row.ParentId};
                ReSubmitApproval(para).then((res) => {
                    if(res.data.success) {
                        this.$message({
                            message: res.data.msg,
                            type: 'success'
                        });
                         this.getOrganizeCos();
                        // window.location.reload();
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                });
            });
        },
             //指派
                handleAssign(){
                      let row = this.currentRow;
                        if (!row) {
                        this.$message({
                            message: "请选择要指派的一行数据！",
                            type: "error",
                            duration: 10000
                        });
                            return;
                    }else if(row.Status != 0){
                        this.$message({
                                message: '当前任务未审核通过，不能指派',
                                type: 'error',
                                duration: 10000
                            });
                    }else if (row.ParentId != null){
                        this.$message({
                                message: '当前任务为子任务，不能指派',
                                type: 'error',
                                duration: 10000
                            });
                    }else{
                            this.addAssignFormVisible = true;
                            this.singleAss = true;
                            this.addForm.WorkItem2 = row.WorkItem;
                            this.addForm.WorkDescription2 = row.WorkDescription;
                    }
                },
                 //取消任务
                    handleCancel(){
                        let row = this.currentRow;
                        if (!row) {
                        this.$message({
                            message: "请选择要取消的一行数据！",
                            type: "error",
                            duration: 10000
                        });
                            return;
                    }
                    var ucode = JSON.parse(window.localStorage.user);
                    this.$confirm('确认取消任务吗？', '提示', {}).then(() => {
                        let para = {
                            taskId: row.Id,
                            wantCancelUserCode: ucode.sub,
                            wantCancelUserName: ucode.name
                            };
                            CancelTask(para).then((res) => {
                                    if(res.data.success) {
                                        this.$message({
                                            message: res.data.msg,
                                            type: 'success'
                                        });
                                        this.currentRow = null;
                                        this.getOrganizeCos();
                                    }else{
                                        this.$message({
                                            message: res.data.msg,
                                            type: 'error'
                                        });
                                    }
                            });
                        });
                    },
     //编辑
        handleEdit(){
             let row = this.currentRow;
               if (!row) {
               this.$message({
                message: "请选择要编辑的一行数据！",
                type: "error",
                duration: 10000
             });
                return;
           }else if(row.TaskProgressValue != 0){
                this.$message({
                    message: '当前任务已开始进行，不能编辑',
                    type: 'error',
                    duration: 10000
                });
           }else if(row.Status == 4 || row.Status == 8){
                this.$message({
                    message: '当前任务仍在审核中，不能编辑',
                    type: 'error',
                    duration: 10000
                });
           }else if (row.ParentId != null){
               this.$message({
                    message: '当前任务为子任务，不能编辑',
                    type: 'error',
                    duration: 10000
                });
           }else{
               if(row.TaskClassify ==1){  
                    this.CommonTaskClassifyText = row.CommonTaskClassifyText;
                }else{
                    if(row.TaskClassify ==2)
                    {
                        this.CommonTaskClassifyText = "跨部门协调";
                    }
                    else if(row.TaskClassify ==3)
                    {
                        this.CommonTaskClassifyText =  "例会项目";
                    }else if(row.TaskClassify ==4)
                    {
                        this.CommonTaskClassifyText =  "年度计划";
                    }else if(row.TaskClassify ==5){
                        this.CommonTaskClassifyText = "专项会议";
                    }
                    else {
                        this.CommonTaskClassifyText =  "其他";
                    }
                }
                //  GetClassifyList().then((res) => {
                //     this.CommonTaskClassifyList = res.data.response;
                // });
                this.editFormVisible = true;
                this.editForm = Object.assign({},row);
           }
        },
        //编辑提交
        editSubmit(){
            this.$refs.editForm.validate((valid) => {
                if(valid){
                    let para = Object.assign({},this.editForm);
                    UpdateTaskContent(para).then((res) => {
                        if(res.data.success) {
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            });
                            this.$refs['editForm'].resetFields();
                            this.currentRow = null;
                            this.editFormVisible = false;
                            this.getOrganizeCos();
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                }
            });
        },
             //删除
                handleDelete(){
                    let row = this.currentRow;
                    if (!row) {
                    this.$message({
                        message: "请选择要删除的一行数据！",
                        type: "error",
                        duration: 10000
                    });
                        return;
                }else if (row.ParentId != null){
                    this.$message({
                            message: '当前任务为子任务，不能删除',
                            type: 'error',
                            duration: 10000
                        });
                }else{
                     this.$confirm('确认删除任务吗？', '提示', {}).then(() => {
                    let para = {taskId: row.Id};
                    DeleteTaskById(para).then((res) => {
                        if(res.data.success) {
                                this.$message({
                                    message: res.data.msg,
                                    type: 'success'
                                });
                                this.currentRow = null;
                                this.getOrganizeCos();
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'error'
                                });
                            }
                    });
                  });
                 }
                },
        //双击查看详细信息
            checkInfo(row){
            var ucode = JSON.parse(window.localStorage.user);
            if(row.TaskClassify ==1){  
                    this.CommonTaskClassifyText = row.CommonTaskClassifyText;
                }else{
                    if(row.TaskClassify ==2)
                    {
                        this.CommonTaskClassifyText = "跨部门协调";
                    }
                    else if(row.TaskClassify ==3)
                    {
                        this.CommonTaskClassifyText =  "例会项目";
                    }else if(row.TaskClassify ==4)
                    {
                        this.CommonTaskClassifyText =  "年度计划";
                    }else if(row.TaskClassify ==5){
                        this.CommonTaskClassifyText = "专项会议";
                    }
                    else {
                        this.CommonTaskClassifyText =  "其他";
                    }
                }
                if(row.PersonOfDuty != ucode.name){
                    this.showAssign = true;
                }else{
                    this.showAssign = false;
                }
            this.checkVisible = true;
            this.pValue1 = row.TaskProgressValue;
            this.pValue2 = row.TaskProgressValue;
            // this.progresscheck = row.ChildrenProgressComplated;
            if(row.PersonOfDuty != ucode.name || row.Status != 0){
                this.isocpro = false;
            }else{
                if(this.pValue1 < 100){
                    this.isocpro = true;
                    // if(this.pValue1 == 95 && this.progresscheck == false){
                    //     this.isocpro = false;
                    // }else{
                    //     this.isocpro = true;
                    // }  
                }else{
                    this.isocpro = false;
                }
            }
            GetClassifyList().then((res) => {
                this.CommonTaskClassifyList = res.data.response;
             });
            this.checkForm = Object.assign({},row);
            let para = {taskId: row.Id};
            QueryTaskProgressByTaskId(para).then((res) => {
                this.ocs = res.data.response;
            });
            },
            checkclose(){
            this.checkVisible = false;
            this.pValue1 = 0;
            this.getOrganizeCos();
        },
          //进度新增
        addpro(){
            this.addProgressVisible = true;
            this.addProgressForm.ProgressDescription = null;
            this.showminus = false;
            this.showplus = true;
        },
        addopen(){
            // if(this.progresscheck == false){
            //     this.$message({
            //         message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
            //         type: "warning",
            //         duration: 10000
            //     });
            // }
        },
        //进度新增提交
        checkSubmit(){
            var user = JSON.parse(window.localStorage.user);
            let para = {
                taskID: this.checkForm.Id,
                progressDescription: this.addProgressForm.ProgressDescription,
                progressValue:  this.pValue2,
                createdId: user.sub,
                createdBy: user.name
            };
            AddTaskProgress(para).then((res) => {
                if(res.data.success) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.addProgressVisible = false;
                    let para = {taskId: this.checkForm.Id};
                    QueryTaskProgressByTaskId(para).then((res) => {
                        this.ocs = res.data.response;
                        this.RefreshProgress(this.ocs);
                    });
                    this.getOrganizeCos();
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });
        },
        //删除选中进度
        deletepro(){
         let row = this.proRow;
               if (!row) {
               this.$message({
                message: "请选择要删除的一行数据！",
                type: "error",
                duration: 10000
             });
                return;
           }
           var length=this.ocs.length;
           if(this.currentProgressRowIndex<length-1)
           {
               this.$message({
                message: "只允许删除最后一条进度",
                type: "error",
                duration: 10000
             });
             return;
           }
           this.$confirm('确认删除任务吗？', '提示', {}).then(() => {
                DeleteTaskProgress({id: row.Id}).then((res) => {
                        if(res.data.success) {
                            this.$message({
                                message: res.data.msg,
                                type: 'success'
                            });
                            let para = {taskId: this.checkForm.Id};
                            QueryTaskProgressByTaskId(para).then((res) => {
                                this.ocs = res.data.response;
                                this.RefreshProgress(this.ocs);
                            });
                            this.getOrganizeCos();
                            }else{
                                this.$message({
                                    message: res.data.msg,
                                    type: 'error'
                                });
                            }
                });
           })
        },
         //责任人回调
        dutycallFunction(newdata){
            this.dutyneedname = null;
            this.addForm.PersonOfDutyCode = newdata[0];
             let para = {userId: newdata[0]};
                GetUserPostList(para).then((res) => {
                    for(var i=0; i<res.data.data.length; i++){
                        this.addForm.PersonOfDuty = res.data.data[0].name;
                        this.dutyneedname = this.addForm.PersonOfDuty;
                    }
                });
        },
        //指派人回调
        assigncallFunction(newdata){
                this.needName = null;
                this.addForm.AssignCode = newdata[0];
                let para = {userId: newdata[0]};
                GetUserPostList(para).then((res) => {
                    for(var i=0; i<res.data.data.length; i++){
                        this.addForm.AssignName = res.data.data[0].name;
                        this.needName = this.addForm.AssignName;
                    }
                });
        },
        //跨出人员回调
        outcallFunction(newdata,dname){
            this.outpeoplename = null;
             this.addForm.OutDepartment = dname;
            let para = {userId: newdata[0]};
            GetUserPostList(para).then((res) => {
                for(var i=0; i<res.data.data.length; i++){
                    this.addForm.OutPeople = res.data.data[0].name;
                    this.outpeoplename = this.addForm.OutPeople;
                }
            });
        },
        //跨入人员回调
        acceptcallFunction(newdata,dname){
            this.acceptpeoplename = null;
            this.addForm.AcceptDepartment = dname;
            this.addForm.AcceptPeopleCode = newdata[0];
            this.getLeader(newdata[0]);
            let para = {userId: newdata[0]};
            GetUserPostList(para).then((res) => {
                for(var i=0; i<res.data.data.length; i++){
                    this.addForm.AcceptPeople = res.data.data[0].name;
                    this.acceptpeoplename = this.addForm.OutPeople;
                }
            });
        },
        clickAssign(){
            if(this.addForm.IsAssign == true){
                this.showNext = true;
                this.showSubmit = false;
            }else{
                this.showSubmit = true;
                this.showNext = false;
            }
        },
        clickclose(){
            this.addForm = {                   //新增表单初始化
                CommonTaskClassify: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsAssign: false,
                CreatedBy: null,
                CreatedId: null,
                AssignName: null,
                AssignCode: null,
                PersonOfDuty: null,
                PersonOfDutyCode: null
            }
            this.$refs.addForm.resetFields();
        },
        //下一步
        nextStep(){
            if(this.addForm.WorkItem != null && this.addForm.WorkDescription != null && this.addForm.PlanComplateTime != null){
                            this.addAssignFormVisible = true;
                            this.addForm.WorkItem2 = this.addForm.WorkItem;
                            this.addForm.WorkDescription2 = this.addForm.WorkDescription;
            }else{
                this.tipMessage();
            }
            
        },
        increase() {
            this.pValue2 += 5;
             if(this.pValue2 > this.pValue1){
                    this.showminus = true;
                }
            if(this.progresscheck == true){
                    if (this.pValue2 > 100) {
                        if(this.checkForm.IsNeedApproval == false){
                            this.$message({
                            message: '任务已完成，请提交！',
                            type: 'success'
                        });
                        }else{
                            this.$message({
                                message: '任务已完成，请提交审核！',
                                type: 'warning'
                            });
                        }
                        
                    this.pValue2 = 100;
                  }
                }else{
                    if (this.pValue2 == 95) {
                            this.$message({
                            message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
                            type: "warning",
                            duration: 10000
                        });
                        this.showplus = false;
                        this.this.pValue2 = 95;
                    }
                }
        },
         decrease(){
                this.pValue2 -= 5;
            if(this.pValue2 <= this.pValue1){
                this.showminus = false;
            }
            if(this.progresscheck == false){
                if(this.pValue2 < 95){
                    this.showplus = true;
                }else{
                    this.showplus = true;
                }
            }
        },
        tipMessage(){
           this.$message({
                message: '填写未完成，请继续填写',
                type: 'warning'
            });
        },
        RefreshProgress(tasksProgressRow){
            var rowlength= tasksProgressRow.length;
            if(rowlength>0)
            {
                this.pValue2 = tasksProgressRow[tasksProgressRow.length-1].ProgressValue;
            }else
            {
                this.pValue2 = 0;
            }
            this.pValue1 = this.pValue2;
        //   if(this.pValue1 == 95 && this.progresscheck == false){
        //         this.isocpro = false;
        //     }else{
        //         this.isocpro = true;
        //     }
        }
    },
    mounted() {
            this.getOrganizeCos();
            var user = JSON.parse(window.localStorage.user);
            this.localuser = user.name;
            let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
             : [];
            //第一种写法，每个页面都需要写方法，但是可以做特性化处理
            // this.getButtonList(routers);
            
            //第二种写法，封装到 permissionRouter.js 中
    let  buttons = window.localStorage.buttList? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
    }
}
</script>
<style scoped>

.ocdiv,.occard {
 height: 600px;
}
.organizediv {
    max-height: 400px;
    overflow: auto;
}

</style>